'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

var _step = 5;

$('#data_list_search_form_Structure .checkbox .default').map (function() {
	$(this).click(function() {
		$('#form_Structure').submit();
	});
});

$('#input_region').change(function() {
	$('#form_Structure').submit();
});

/*
 * gestion des cases a cocher pour la recherche des notices
 */
$('#form_Notice .group-content .group .group-content').map (function() {
	var _instep = 1;
	var _more = false;
	$(this).children().filter('.checkbox').map(function() {
		if (_instep++ > _step) {
			$(this).hide();
			_more = true;
		}
	});

	if (_more === true) {
		aMore($(this));
	}
});

$('#form_Publication .group-content .group .group-content').map (function() {
	var _instep = 1;
	var _more = false;
	$(this).children().filter('.checkbox').map(function() {
		if (_instep++ > _step) {
			$(this).hide();
			_more = true;
		}
	});

	if (_more === true) {
		aMore($(this));
	}
});

$('#form_Transverse .group-content .group .group-content').map (function() {
	var _instep = 1;
	var _more = false;
    _step = 10;
	$(this).children().filter('.checkbox').map(function() {
		if (_instep++ > _step) {
			$(this).hide();
			_more = true;
		}
	});

	if (_more === true) {
		aMore($(this));
	}
});

function aMore(_this) {

	var $elementVoirPlus = document.createElement("a");
	$elementVoirPlus.setAttribute('href', 'javascript:void(0);');
	$elementVoirPlus.setAttribute('class', 'more');
	$($elementVoirPlus).text('Voir plus');
	_this.append($elementVoirPlus);

	var more = false;

	$($elementVoirPlus).click(function() {

		var _hidden = 0;

		$($elementVoirPlus).parent('div.group-content').children().filter('.checkbox').map(function() {

			if ($(this).css('display') === 'none') {
				if (_hidden++ < _step) {
					$(this).toggle(100);
				}
				else {
					more = true;
				}
			}
		});

		$($elementVoirPlus).hide();

		if (more == true) {
			aMore ($($elementVoirPlus).parent('div.group-content'));
		}
		else {
			var $elementVoirMoins = document.createElement("a");

			$elementVoirMoins.setAttribute('href', 'javascript:void(0);');
			$($elementVoirMoins).text('Voir moins');

			_this.append($elementVoirMoins);

			$($elementVoirMoins).click(function() {
				var __cnt = 0;
				$($elementVoirMoins).parent('div.group-content').children().filter('.checkbox').map(function() {
					if (__cnt++ > _step) {

						$(this).toggle(100);

						if ($($elementVoirMoins).hasClass('more')) {
							$($elementVoirMoins).toggleClass('more');
							$($elementVoirMoins).text('Voir moins');
						}
						else {
							$($elementVoirMoins).toggleClass('more');
							$($elementVoirMoins).text('Voir plus');
						}

					}
				});
			});
		}
	});

}
