'use strict';

//Point d'entrée du build de bundle.js
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
require('../bower_components/bootstrap-sass-official/assets/javascripts/bootstrap');

$(document).ready(function() {

	//Chargement des modules jQuery
	require('./modules/annonces');
	require('./modules/connexion');
	require('./modules/recherches');

	require('./components/menu');
	require('./components/lightbox');

});
