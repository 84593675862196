'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

//Chargement de la lightbox
require('../../bower_components/ekko-lightbox/dist/ekko-lightbox');

//Chargement des modals bootstrap
require('../../bower_components/bootstrap-sass-official/assets/javascripts/bootstrap');

$(document).delegate('*[rel="lightbox"]', 'click', function(event) {

	//On evite d'ouvrir les liens dans un nouvel onglet :)
	event.preventDefault();

	//On set le title avec l'alt de l'image pour la API de la lightbox
	var title = $(this).find('img').first().attr('alt');
	$(this).attr('data-title', title);

	//On instancie la lightbox
	$(this).ekkoLightbox();
});
