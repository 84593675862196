'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

// Pour le niveau 0
// On toggle la class open au click sur le bouton open
$('#menu_toggle').click(function() {
	$('#menu_default .level_0').toggleClass('open');
});

//Fonction pour cache les niveaux 1
var hideMenu = function() {
	$('.has_children').removeClass('open');
};

var handler = function(event) {
	if (!$('#menu_default')[0].contains(event.target)) {
		hideMenu();
		$(document).unbind('click', handler);
	}
};

// Pour le niveau 1
$('.li_level_0.has_children > a').click(function(event) {
	event.preventDefault();
	var $this = $(this).parent();
	var open = $this.hasClass('open');
	hideMenu();
	if (!open) {
		$this.addClass('open');
		$(document).bind('click', handler);
	}
});

//Pour le bouton fermer
$('.menu .closebtn').click(function() {
	hideMenu();
});
