'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
//Chargement de la lib pour l'autocompletetion
require('../../bower_components/typeahead.js/dist/typeahead.jquery.min');
require('../../bower_components/jquery-file-upload/js/jquery.fileupload');
require('../../bower_components/jquery-file-upload/js/vendor/jquery.ui.widget');
require('../../bower_components/jquery-file-upload/js/jquery.iframe-transport');
//Pour la gestion de l'autocomplétion
var _auto = document.getElementById('typeahead-annonce');
//Pour la gestion de l'information sur les catégories
var _categorie = document.getElementById('pa_categorie_id');
//On vérifie que le champ existe sur la page
if (_auto) {
	//Les options de l'autocomplétion
	var opt_auto = {
		hint: false,
		highlight: true,
		minLength : 2
	};
	$('#typeahead-annonce .typeahead').typeahead(opt_auto,
		{
			limit: 100,
			async: true,
			display: 'name',
			source: function(query, processSync, processAsync){
				return $.ajax({
					url: "/annonces/communes_autocomplete",
					type: 'GET',
					data: {query: query},
					dataType: 'json',
					success: function(json){
						return processAsync(json);
					}
				});
			}
		}
	);
	//Après la sélection de la valeur on va mettre à jour l'id de la commune dans le champ caché
	$('#typeahead-annonce .typeahead').bind('typeahead:select', function(ev, suggestion) {
		$('#auto_commune_id').val(suggestion.id);
	});
}

if(_categorie){
	var url = '/annonces/upload';
	$('#pa_categorie_id').change(function(){
		var cat_id = $(this).val();
		_updateInfoCat(cat_id);
	});

	$('#pa_mise_en_valeur').change(function(){
		var cat_id = $('#pa_categorie_id').val();
		$('#pa_bloc_option').toggleClass('hidden');
		_updateInfoCat(cat_id);
	});

	$('.fileupload').fileupload({
		url: url,
		dataType: 'json',
		done: function (e, data) {
			var _this = $(this);
			$.each(data.result.data, function (index, file) {
				var typeModelId = _this.data('type');
				$('#'+typeModelId).attr('value',file.name);
				$('#'+typeModelId+'List').text(file.name);
			});
		}

	});
}



function _updateInfoCat(cat_id){
	$.ajax({
		url: "/annonces/getInfoCategorie/"+cat_id,
		type: 'GET',
		dataType: 'json',
		success: function(data){
			var prix = parseInt(data.prix);
			$('#helpBlockCategory').html(data.desc);
			$('#pa_prix_option').html(data.prix_option);
			$('#pa_date_annonce').html(data.duree);
			if($('#pa_mise_en_valeur').is(':checked')){
				prix = prix + parseInt(data.prix_option);
			}
			$('#pa_prix_total').html(prix);
		}
	});
}
