'use strict';
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

// On affiche/cache la boite de login si on est pas encore connecté
$('#toggle_form_login').click(function() {
	$('#li_form_login').toggleClass('active');
	$('#li_form_login #connection').toggle(250);
});

// On affiche/cache la boite de login une fois connecté
$('#toggle_logout').click(function() {
	$('#li_form_login').toggleClass('active');
	$('#li_links_login').toggleClass('active');
	$('#li_links_login #disconnect').toggle(250);
});

// Bouton d'accès au menu pour RWD

